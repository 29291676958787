import React, { useEffect, useRef, useState } from 'react';

import { Box, darken, Typography, useTheme } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { SHOW_MEAN_ON_MEASUREMENT_GRAPH, SHOW_MEDIAN_ON_MEASUREMENT_GRAPH } from '../../../rules';
import { formatAsUtcDate, formatAsUtcMonth } from '../../../utils/dateUtils';
import { intersection } from 'lodash';
import { useMeasurement, usePatientData, useShowDataWithIssues } from '../../../stores/dataStore';
import { getMeasurementsInRange } from '../../../helpers/dataTransforms';
import { computeNewTickInterval } from '../../../utils/highchartsUtils';
import { isBefore } from 'date-fns';
import { useActiveDateRange } from '../../../stores/dateRangeStore';
import { PatientMeasurementNormality } from '../../../../../data/PatientApprovalData';
import assertExhaustive from '../../../utils/assertExhaustive';

const MeasurementGraph: React.FC = () => {
  const theme = useTheme();
  const chartComponentRef = useRef<HighchartsReactRefObject>(null);

  const { loading, data: patientData } = usePatientData();
  const activeDateRange = useActiveDateRange();
  const measurement = useMeasurement();

  const [chartData, setChartData] = useState<Partial<Highcharts.Point>[]>([]);
  const [minY, setMinY] = useState<number | undefined>();
  const [maxY, setMaxY] = useState<number | undefined>();
  const [mean, setMean] = useState<number | undefined>();
  const [median, setMedian] = useState<number | undefined>();

  const showIssuesInTooltipRef = useRef<boolean>(false);
  const currentIssuesRef = useRef<string[]>([]);
  useEffect(() => {
    showIssuesInTooltipRef.current = false;
  }, [loading, patientData]);

  const showDataWithIssues = useShowDataWithIssues();
  useEffect(() => {
    if (loading || !patientData || !measurement) {
      setChartData([]);
      currentIssuesRef.current = [];
      return;
    }

    const measurementsInRange = getMeasurementsInRange(patientData.measurements, activeDateRange);
    const measurementData = measurementsInRange
      .filter(pm => pm.type === measurement.type)
      // Never show near-zero values that are flagged with issues because it is very unlikely we would ever use them,
      // and they can cause the graph to be scaled much larger than it needs to be.
      .filter(pm => !(pm.issues.length > 0 && pm.average < 0.00000000001))
      .filter(pm => {
        switch (showDataWithIssues) {
          case 'noIssues':
            return pm.issues.length === 0;
          case 'onlyIssues':
            return pm.issues.length > 0;
          case 'all':
            return true;
          default:
            assertExhaustive(showDataWithIssues);
            throw new Error('Unreachable');
        }
      })
      .filter(pm => intersection(measurement.codes, pm.codes).length > 0);

    const chartData = measurementData.map(pm => ({
      x: new Date(pm.performedWeek).getTime(),
      y: pm.average ?? 0,
      color:
        pm.issues.length > 0
          ? theme.palette.grey[500]
          : pm.normality === 'normal'
          ? theme.palette.success.main
          : pm.normality === 'abnormal'
          ? theme.palette.error.main
          : undefined,
      events: {
        click: function () {
          const self = this as unknown as Highcharts.Point;
          showIssuesInTooltipRef.current = !showIssuesInTooltipRef.current;
          self.series.chart.redraw();
        },
        mouseOver: function () {
          currentIssuesRef.current = pm.issues;
        },
      },
    }));
    setChartData(chartData);

    let min = measurement.normalMins.map(b => b.value).reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE);
    let max = measurement.normalMaxes.map(b => b.value).reduce((acc, val) => Math.max(acc, val), 0);
    measurementData.forEach(pm => {
      if (pm.average) {
        if (pm.average <= min) {
          min = pm.average;
        }

        if (pm.average >= max) {
          max = pm.average;
        }
      }
    });

    const range = max - min;
    const margin = range * 0.1;
    setMinY(Math.max(min - margin, 0));
    setMaxY(max + margin);

    // if these aren't set then don't incur the costs to compute
    if (SHOW_MEAN_ON_MEASUREMENT_GRAPH || SHOW_MEDIAN_ON_MEASUREMENT_GRAPH) {
      // we're going to need this anyway, so pull out the data and sort it
      const values = measurementData
        .map(d => d.average)
        .filter(v => v)
        .sort((a, b) => a - b);

      if (SHOW_MEAN_ON_MEASUREMENT_GRAPH) {
        // compute the mean
        let s = 0;
        let c = 0;
        values.forEach(v => {
          s += v;
          c++;
        });
        setMean(c !== 0 ? s / c : undefined);
      }

      if (SHOW_MEDIAN_ON_MEASUREMENT_GRAPH) {
        // compute the median
        let m: number | undefined = undefined;
        if (values.length % 2 === 0) {
          // even, grab the middle two and average
          m = (values[Math.floor(values.length / 2) - 1] + values[Math.floor(values.length / 2)]) / 2.0;
        } else {
          // grab the center
          m = values[Math.floor(values.length / 2)];
        }
        setMedian(m);
      }
    }
  }, [loading, patientData, activeDateRange, measurement, showDataWithIssues, currentIssuesRef, theme]);

  const labelWidthPx = 55;
  const [tickInterval, setTickInterval] = useState<number>(Number.MAX_SAFE_INTEGER);

  if (chartData?.length === 0) {
    return (
      <Box
        sx={{
          height: '100%',
          border: '1px solid white',
          paddingBottom: 0,
        }}
        justifyContent='center'
        alignItems='center'
        display='flex'
      >
        <Typography variant='h5'>No available data</Typography>
      </Box>
    );
  }

  const fontStyle = (fontSize: number) => ({
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    textOverflow: 'none',
    fontFamily: theme.typography.fontFamily,
    fontSize: `${fontSize}px`,
  });

  const xAxisPlotLines = [];

  let diagnosesBeforeStart = 0;

  if (patientData?.diagnoses) {
    for (const diagnosis of patientData.diagnoses) {
      if (diagnosis.issues.length > 0 && showDataWithIssues === 'noIssues') {
        continue;
      }

      if (diagnosis.issues.length === 0 && showDataWithIssues === 'onlyIssues') {
        continue;
      }

      const date = new Date(diagnosis.diagnosisDate);
      const isBeforeStart = isBefore(date, activeDateRange.earliest);
      if (isBeforeStart) {
        diagnosesBeforeStart++;
        continue;
      }

      xAxisPlotLines.push({
        color: diagnosis.issues.length === 0 ? theme.palette.warning.main : theme.palette.error.main,
        width: 1,
        value: isBeforeStart ? activeDateRange.earliest.getTime() : date.getTime(),
        label: {
          text: isBeforeStart ? '...' : diagnosis.name,
          style: {
            ...fontStyle(10),
          },
        },
      });
    }

    if (diagnosesBeforeStart > 0) {
      xAxisPlotLines.push({
        color: theme.palette.warning.main,
        width: 1,
        value: activeDateRange.earliest.getTime(),
        label: {
          text: `(${diagnosesBeforeStart} ${diagnosesBeforeStart === 1 ? 'diagnosis' : 'diagnoses'})`,
          style: {
            ...fontStyle(10),
          },
        },
      });
    }
  }

  if (patientData?.sampleCollectionDates) {
    for (const sampleCollectionDate of patientData.sampleCollectionDates) {
      const date = new Date(sampleCollectionDate.collectionDate);

      xAxisPlotLines.push({
        color: theme.palette.info.main,
        width: 1,
        value: date.getTime(),
        label: {
          text:
            sampleCollectionDate.sampleCount === 1
              ? `${sampleCollectionDate.sampleCount} sample`
              : `${sampleCollectionDate.sampleCount} samples`,
          style: {
            ...fontStyle(10),
          },
        },
      });
    }
  }

  const yAxisPlotLines = [];
  for (const minBound of measurement?.normalMins ?? []) {
    yAxisPlotLines.push({
      label: {
        text: `${minBound.description} ${minBound.value}`,
        align: 'left',
        style: {
          ...fontStyle(9),
        },
      },
      value: minBound.value,
    });
  }

  for (const maxBound of measurement?.normalMaxes ?? []) {
    yAxisPlotLines.push({
      label: {
        text: `${maxBound.description} ${maxBound.value}`,
        align: 'left',
        style: {
          ...fontStyle(9),
        },
      },
      value: maxBound.value,
    });
  }

  if (SHOW_MEAN_ON_MEASUREMENT_GRAPH && mean !== undefined) {
    yAxisPlotLines.push({
      label: {
        text: `mean ${mean.toFixed(2)}`,
        align: 'right',
        style: {
          ...fontStyle(9),
        },
      },
      value: mean,
      dashStyle: 'ShortDash',
    });
  }

  if (SHOW_MEDIAN_ON_MEASUREMENT_GRAPH && measurement !== undefined && median !== undefined) {
    const smallestMin = measurement.normalMins
      .map(b => b.value)
      .reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE);
    const largestMin = measurement.normalMins.map(b => b.value).reduce((acc, val) => Math.max(acc, val), 0);
    const smallestMax = measurement.normalMaxes
      .map(b => b.value)
      .reduce((acc, val) => Math.min(acc, val), Number.MAX_VALUE);
    const largestMax = measurement.normalMaxes.map(b => b.value).reduce((acc, val) => Math.max(acc, val), 0);

    let valueNormality: PatientMeasurementNormality = 'undetermined';
    switch (measurement.rangeType) {
      case 'withinIsNormal':
        if (median >= largestMin && median <= smallestMax) {
          valueNormality = 'normal';
        } else if (median < smallestMin || median > largestMax) {
          valueNormality = 'abnormal';
        } else {
          valueNormality = 'undetermined';
        }
        break;
      case 'aboveIsAbnormal':
        if (median > largestMax) {
          valueNormality = 'abnormal';
        } else if (median < smallestMin) {
          valueNormality = 'normal';
        } else {
          valueNormality = 'undetermined';
        }
        break;
      case undefined:
        valueNormality = 'undetermined';
        break;
      default:
        assertExhaustive(measurement.rangeType);
    }

    let [textColor, lineColor] =
      valueNormality === 'normal'
        ? [darken(theme.palette.success.main, 0.1), theme.palette.success.main]
        : valueNormality === 'abnormal'
        ? [theme.palette.error.main, theme.palette.error.main]
        : valueNormality === 'undetermined'
        ? [darken('rgb(44, 175, 254)', 0.1), 'rgb(44, 175, 254)'] // Highcharts default series color
        : assertExhaustive(valueNormality);

    yAxisPlotLines.push({
      label: {
        text: `median ${median.toFixed(2)}`,
        align: 'right',
        style: {
          ...fontStyle(10),
          color: textColor,
        },
      },
      value: median,
      dashStyle: 'Dash',
      color: lineColor,
      width: 2,
    });
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        border: '1px solid lightgray',
        borderRadius: theme.spacing(1),
      }}
    >
      <HighchartsReact
        containerProps={{
          style: {
            position: 'absolute',
            top: 0,
            left: theme.spacing(1),
            bottom: 0,
            right: theme.spacing(1),
          },
        }}
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'scatter',
            animation: false,
            marginTop: Number(theme.spacing(1).slice(0, -2)),
            marginBottom: Number(theme.spacing(3).slice(0, -2)),
            marginLeft: Number(theme.spacing(5).slice(0, -2)),
            marginRight: Number(theme.spacing(4).slice(0, -2)),
            events: {
              render: function () {
                const chart = this as unknown as Highcharts.Chart;
                const newTickInterval = computeNewTickInterval(chart, labelWidthPx);
                if (newTickInterval !== null) {
                  setTickInterval(newTickInterval);
                }
              },
            },
          },
          boost: {
            enabled: false,
          },
          title: undefined,
          legend: {
            enabled: false,
          },
          xAxis: {
            title: undefined,
            min: activeDateRange.earliest.getTime(),
            max: activeDateRange.latest.getTime(),
            lineColor: theme.palette.text.primary,
            labels: {
              overflow: 'allow',
              autoRotation: false,
              style: {
                ...fontStyle(11),
              },
              distance: Math.floor(Number(theme.spacing(0.75).slice(0, -2))),
              padding: 0,
              formatter: function (): string {
                const self = this as any;
                const date = new Date(self.value);
                return formatAsUtcMonth(date);
              },
            },
            tickLength: 5,
            tickWidth: 1,
            tickInterval: tickInterval,
            plotLines: xAxisPlotLines,
          },
          yAxis: {
            title: undefined,
            min: minY,
            max: maxY,
            endOnTick: false,
            lineWidth: diagnosesBeforeStart === 0 ? 1 : 0,
            lineColor: theme.palette.text.primary,
            gridLineWidth: 0,
            tickLength: 5,
            tickWidth: 1,
            labels: {
              style: {
                ...fontStyle(11),
              },
            },
            plotLines: yAxisPlotLines,
          },
          series: [
            {
              name: measurement?.shortName ?? 'Unknown',
              data: chartData,
              cursor: 'pointer',
              marker: {
                symbol: 'circle',
                fillColor: '#FF000000',
                lineWidth: 2,
                lineColor: null, // inherit from series
              },
            },
          ],
          tooltip: {
            headerFormat: '',
            pointFormatter: function (): string {
              const self = this as any;

              const date = new Date(self.x);
              const header = `${formatAsUtcDate(date)}: <b>${self.y}</b>`;

              const makeIssueLine = (text: string, color: string) =>
                `<span style="color: ${color}">\u2022 ${text}</span>`;

              const body = showIssuesInTooltipRef.current
                ? currentIssuesRef.current.length > 0
                  ? `<br>${currentIssuesRef.current
                      .map(issue => makeIssueLine(issue, theme.palette.error.main))
                      .join('<br>')}`
                  : `<br>${makeIssueLine('No issues', darken(theme.palette.success.main, 0.1))}`
                : '';

              return header + body;
            },
          },
          accessibility: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
        }}
        ref={chartComponentRef}
      />
    </Box>
  );
};

export default MeasurementGraph;
