import React, { useContext, useEffect, useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridPagination, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid-pro';
import { FlexBox } from 'components/FlexBox';
import { FlexTableBox } from 'components/FlexTableBox';
import { GridExportButton } from 'components/GridExportButton';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';
import { renderCellBooleanCheck } from 'components/grid/GridCellBooleanCheck';
import {
  PipelineRule,
  PipelineRulesConfig,
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
} from 'data/SuperDuperFiestaData';
import useAuth from '../../auth/UseAuth';
import { LoadState, LoadingProps } from '../../components/LoadingStateUtil';
import PipelineRulesContext from '../../contexts/PipelineRulesContext';
import { GetPipelineRules } from '../../data/SuperDuperFiestaData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';
import {
  createdAt,
  createdBy,
  dataClassName,
  dataSourceName,
  entityName,
  executedAt,
  executionDurationMs,
  executionOrder,
  field,
  issueName,
  nameField,
  needsReview,
  queryCondition,
  queryGroupBy,
  queryJoin,
  rowsAffected,
  state,
  stepTypeName,
  value,
} from '../../util/Constants';
import { dateGetter, formatMsToHHMMSS } from '../../util/grid/TableUtils';
import { PipelineRulesModal } from './PipelineRulesModal/PipelineRulesModal';

export const PipelineRulesGrid = ({
  loadingProps,
  selectedEntity,
  selectedPipeline,
  selectedStage,
}: {
  loadingProps: LoadingProps;
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
}) => {
  const { accessToken } = useAuth();
  const apiRef = useGridApiRef();
  const { t } = useMemoTranslation();
  const { refreshData, setRefreshData } = useContext(PipelineRulesContext);

  const [, setLoadingState] = loadingProps;

  const [rows, setRows] = useState<PipelineRule[]>([]);
  const [selectedRow, setSelectedRow] = useState<PipelineRule | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const columns = useColumns(setSelectedRow);

  useEffect(() => {
    if (selectedEntity !== null && selectedPipeline !== null && selectedStage !== null) {
      setIsValid(true);
      setRefreshData(true);
    } else {
      setIsValid(false);
    }
  }, [selectedPipeline, selectedStage, selectedEntity, setRefreshData]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (!accessToken) {
        return;
      }

      if (refreshData && selectedEntity !== null && selectedPipeline !== null && selectedStage !== null) {
        const queryParams: PipelineRulesConfig = {
          pipelineId: selectedPipeline.pipelineId,
          stageId: selectedStage.stageId,
          entityId: selectedEntity.entityId,
        };

        const data = await GetPipelineRules(queryParams, accessToken);

        if (data.length === 0) {
          setRows([]);
        } else {
          setRefreshData(false);
          setRows(
            data.map((row, index) => ({
              ...row,
              id: index,
              executionOrderDisplay: index + 1,
              hierarchy: row?.hierarchy !== undefined && row.hierarchy.length !== 0 ? row.hierarchy : [`${index}`],
            }))
          );
        }
      } else {
        setRows([]);
      }
    });
  }, [setLoadingState, accessToken, refreshData, setRefreshData, selectedPipeline, selectedStage, selectedEntity]);

  useEffect(() => {
    if (!selectedRow) {
      return;
    }
    setOpen(true);
  }, [selectedRow]);

  function CustomToolbar() {
    return (
      <FlexBox flexDirection={'row-reverse'}>
        <GridPagination />
        <GridToolbarFilterButton />
      </FlexBox>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <Box
        sx={{ mt: 1 }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        Select a pipeline
      </Box>
    );
  }

  return (
    <FlexTableBox sx={{ gap: 1 }}>
      <FlexTableBox>
        <FlexBox flexDirection={'row-reverse'}>
          <GridExportButton apiRef={apiRef} fileName='pipeline_rules' />
          <PipelineRulesModal
            selectedEntity={selectedEntity}
            selectedPipeline={selectedPipeline}
            selectedStage={selectedStage}
            canOpen={isValid}
            open={open}
            setOpen={setOpen}
            allRows={rows}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        </FlexBox>
        <CompactGridWrapper
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowHeight={25}
          pagination
          pageSizeOptions={[100, 250, 500]}
          slots={{
            footer: CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          treeData
          getTreeDataPath={row => row?.hierarchy}
          groupingColDef={{
            headerName: t(executionOrder),
            width: 125,
            valueFormatter: params => apiRef.current.getRow(params.id!)?.executionOrderDisplay,
          }}
        />
      </FlexTableBox>
    </FlexTableBox>
  );
};

const useColumns = (setSelectedRow: React.Dispatch<React.SetStateAction<PipelineRule | null>>): GridColDef[] => {
  const { t } = useMemoTranslation();

  return useMemo(() => {
    return [
      {
        field: nameField,
        headerName: t(nameField),
        width: 250,
        renderCell: (params: any) => (
          <Tooltip title={params.row.description || params.value.toString()}>
            <span className='table-cell-trucate'>{params.value.toString()}</span>
          </Tooltip>
        ),
      },
      {
        field: stepTypeName,
        headerName: t(stepTypeName),
        width: 150,
      },
      {
        field: entityName,
        headerName: t(entityName),
        width: 150,
      },
      {
        field: dataSourceName,
        headerName: t(dataSourceName),
        width: 150,
      },
      {
        field: dataClassName,
        headerName: t(dataClassName),
        width: 150,
      },
      {
        field: issueName,
        headerName: t(issueName),
        width: 150,
      },
      {
        field: needsReview,
        headerName: t(needsReview),
        width: 100,
        align: 'center',
        renderCell: renderCellBooleanCheck,
      },
      {
        field: queryCondition,
        headerName: t(queryCondition),
        width: 250,
      },
      {
        field: queryJoin,
        headerName: t(queryJoin),
        width: 250,
      },
      {
        field: queryGroupBy,
        headerName: t(queryGroupBy),
        width: 150,
      },
      {
        field: field,
        headerName: t(field),
        width: 100,
      },
      {
        field: value,
        headerName: t(value),
        width: 100,
      },
      {
        field: createdBy,
        headerName: t(createdBy),
        width: 100,
      },
      {
        field: createdAt,
        headerName: t(createdAt),
        type: 'dateTime',
        valueGetter: dateGetter,
        width: 200,
      },
      {
        field: rowsAffected,
        headerName: t(rowsAffected),
        width: 100,
      },
      {
        field: executionDurationMs,
        headerName: t('executionDuration'),
        valueGetter: params =>
          params.row.executionDurationMs ?? new Date().getTime() - new Date(params.row.executedAt + 'Z').getTime(),
        valueFormatter: ({ value }) => {
          if (isNaN(value)) {
            return '' as const;
          }
          return `${formatMsToHHMMSS(value)} -- (${(value as number).toLocaleString()} ms)`;
        },
        width: 200,
      },
      {
        field: state,
        headerName: t(state),
        width: 100,
      },
      {
        field: executedAt,
        headerName: 'Last Executed At',
        type: 'dateTime',
        valueGetter: dateGetter,
        width: 200,
      },
      {
        field: 'action',
        width: 50,
        renderCell: (params: any) => {
          if (params.row.hierarchy.length <= 1) {
            return (
              <IconButton onClick={() => setSelectedRow(params.row)} color='primary' sx={{ pr: 0 }}>
                <EditIcon fontSize='small' />
              </IconButton>
            );
          }
        },
      },
    ];
  }, [t, setSelectedRow]);
};
