import React, { ChangeEvent, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingStatus } from 'components/LoadingStatus';
import { CreateCustomerModal } from './CreateCustomerModal';
import { LlCustomer } from 'data/LlCustomerData';
import { ResearchProject, ResearchProjectDetails } from 'data/ResearchProjectData';
import { LlCustomerOption, ResearchProjectStatusOption } from './ResearchProjectAutocompleteOptions';
import { appSettings } from 'AppSettings';
import useAuth from 'auth/UseAuth';
import { DialogCloseButton } from '../components/DialogCloseButton';
import { useResearchProjectStatusOptions } from 'components/hooks/UseResearchProjectStatusOptions';
import { CancelButton } from 'components/CancelButton';
import { DeleteButton } from 'components/DeleteButton';
import { PrimaryButton } from 'components/PrimaryButton';

export interface UpdateResearchProjectModalProps {
  open: boolean;
  researchProject: ResearchProject;
  customer: LlCustomer;
  customerOptions: LlCustomerOption[];
  onResearchProjectUpdate: Function;
  onCustomerCreate: Function;
  onClose: Function;
}

export const UpdateResearchProjectModal = ({
  open,
  researchProject,
  customer,
  customerOptions,
  onResearchProjectUpdate,
  onCustomerCreate,
  onClose,
}: UpdateResearchProjectModalProps) => {
  const { accessToken } = useAuth();

  const [researchProjectStatusOptions] = useResearchProjectStatusOptions();

  const defaultResearchProjectStatus: ResearchProjectStatusOption = {
    label: researchProject.status,
    status: researchProject.status,
  };

  const [modalOpen, setModalOpen] = useState<boolean>(open);
  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedCustomer, setSelectedCustomer] = useState<LlCustomerOption | undefined>({
    label: customer.name,
    customer: customer,
  });
  const [selectedProjectName, setSelectedProjectName] = useState<string>(researchProject.name);
  const [selectedExpectedCohortSize, setSelectedExpectedCohortSize] = useState<number | undefined>(
    researchProject.expectedCohortSize
  );
  const [selectedResearchProjectStatus, setSelectedResearchProjectStatus] =
    useState<ResearchProjectStatusOption>(defaultResearchProjectStatus);
  const [selectedSalesforceLink, setSelectedSalesforceLink] = useState<string | undefined>(
    researchProject.salesforceLink
  );
  const [selectedMondayLink, setSelectedMondayLink] = useState<string | undefined>(researchProject.mondayLink);

  const handleClose = () => {
    clearSelectedState();
    setModalOpen(false);
    onClose();
  };

  const handleSave = async () => {
    setStatus('Loading');

    if (selectedProjectName === undefined) {
      setErrorMessage('Please enter a project name.');
      setStatus('Error');
    } else if (selectedResearchProjectStatus === undefined) {
      setErrorMessage('Please select a project status.');
      setStatus('Error');
    } else if (selectedCustomer === undefined) {
      setErrorMessage('Please select a customer status.');
      setStatus('Error');
    } else {
      let updateResearchProject: ResearchProjectDetails = {
        researchProjectId: researchProject.researchProjectId,
        name: selectedProjectName,
        customer: selectedCustomer.customer,
        expectedCohortSize: selectedExpectedCohortSize,
        status: selectedResearchProjectStatus.status,
        salesforceLink: selectedSalesforceLink,
        mondayLink: selectedMondayLink,
      };

      try {
        if (accessToken) {
          const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjects`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateResearchProject),
          });

          if (response.ok) {
            setStatus('Complete');
            handleClose();
            onResearchProjectUpdate();
          } else {
            let responseBody: { message: string } = await response.json();
            setStatus('Error');

            if (responseBody.message) {
              setErrorMessage(responseBody.message);
            } else if (response.status === 401) {
              setErrorMessage('Error: unauthorized user.');
            } else {
              setErrorMessage('Unknown error.');
            }
          }
        }
      } catch (err) {
        setErrorMessage('Unknown error.');
        setStatus('Error');
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (accessToken) {
        const response = await fetch(
          `${appSettings.api.endpoint}/api/v2/ResearchProjects/${researchProject.researchProjectId}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.ok) {
          setStatus('Complete');
          handleClose();
          onResearchProjectUpdate();
        } else {
          let message = await response.text();
          setStatus('Error');
          setErrorMessage(message.length > 0 ? message : 'Unknown error.');
        }
      }
    } catch (err) {
      setStatus('Error');
      setErrorMessage('Unknown error.');
    }
  };

  const clearSelectedState = () => {
    setStatus('NotStarted');
    setErrorMessage(undefined);
    setSelectedCustomer(undefined);
    setSelectedProjectName(researchProject.name);
    setSelectedExpectedCohortSize(researchProject.expectedCohortSize);
    setSelectedResearchProjectStatus(defaultResearchProjectStatus);
    setSelectedSalesforceLink(researchProject.salesforceLink);
    setSelectedMondayLink(researchProject.mondayLink);
  };

  const handleProjectNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedProjectName(ev.target.value);
  };

  const handleLlCustomerChange = (llCustomer?: LlCustomerOption) => {
    setSelectedCustomer(llCustomer);
  };

  async function handleCreateLlCustomer(newCustomer: LlCustomer) {
    if (accessToken) {
      let newCustomerOption: LlCustomerOption = { label: newCustomer.name, customer: newCustomer };
      setSelectedCustomer(newCustomerOption);
      onCustomerCreate();
    }
  }

  const handleExpectedCohortSizeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedExpectedCohortSize(ev.target.value ? Number(ev.target.value) : undefined);
  };

  const handleResearchProjectStatusChange = (researchProjectStatusOption: ResearchProjectStatusOption | null) => {
    setSelectedResearchProjectStatus(researchProjectStatusOption ?? defaultResearchProjectStatus);
  };

  const handleSalesForceLinkChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedSalesforceLink(ev.target.value);
  };

  const handleMondayLinkChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedMondayLink(ev.target.value);
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          <Typography variant='h4'>Update Research Project</Typography>
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ marginTop: 2 }}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Autocomplete
                id='update-research-project-customer-input'
                fullWidth
                value={selectedCustomer}
                options={customerOptions}
                renderInput={params => <TextField {...params} label='Customer *' margin='normal' />}
                onChange={(event, value) => handleLlCustomerChange(value ?? undefined)}
              />
              <CreateCustomerModal onCustomerCreate={handleCreateLlCustomer} />
            </Box>
            <TextField
              id='update-research-project-project-name-input'
              fullWidth
              margin='normal'
              label='Project Name *'
              onChange={handleProjectNameChange}
              type='text'
              value={selectedProjectName}
            />
            <TextField
              id='update-research-project-cohort-size-input'
              fullWidth
              margin='normal'
              label='Expected Cohort Size'
              onChange={handleExpectedCohortSizeChange}
              type='number'
              value={selectedExpectedCohortSize}
            />
            <Autocomplete
              id='update-research-project-status-input'
              fullWidth
              options={researchProjectStatusOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  value={selectedResearchProjectStatus}
                  label='Execution Status *'
                  margin='normal'
                />
              )}
              onChange={(event, value) => handleResearchProjectStatusChange(value)}
              value={selectedResearchProjectStatus}
            />
            <TextField
              id='update-research-project-salesforce-input'
              fullWidth
              margin='normal'
              label='Salesforce Link'
              onChange={handleSalesForceLinkChange}
              type='text'
              value={selectedSalesforceLink}
            />
            <TextField
              id='update-research-project-project-management-input'
              fullWidth
              margin='normal'
              label='Project Managment Link'
              onChange={handleMondayLinkChange}
              type='text'
              value={selectedMondayLink}
            />
          </Box>
          {status === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {status === 'Loading' && <LinearProgress />}
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={status === 'Loading'} />
          <DeleteButton onClick={handleDelete} disabled={status === 'Loading'} />
          <PrimaryButton onClick={handleSave} disabled={status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
